<template>
  <div>
    <a-card :title="$t('标签配置')">
      <a-spin :spinning="loading">
        <a-form :form="form" :label-col="{ span: 12 }" :wrapper-col="{ span: 12 }">
          <a-row>
            <a-col :span="8">
              <a-form-item :label="$t('货品标签长度(mm)')">
                <a-input-number
                  v-decorator="['material_label_length', { rules: [{ required: true, message: $t('请输入货品标签长度') }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('货品标签宽度(mm)')">
                <a-input-number
                  v-decorator="['material_label_width', { rules: [{ required: true, message: $t('请输入货品标签宽度') }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('建议标签比例')"> 2 : 1</a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item :label="$t('批次标签长度(mm)')">
                <a-input-number
                  v-decorator="['stock_in_label_length', { rules: [{ required: true, message: $t('请输入批次标签长度') }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('批次标签宽度(mm)')">
                <a-input-number
                  v-decorator="['stock_in_label_width', { rules: [{ required: true, message: $t('请输入批次标签宽度') }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('建议标签比例')"> 1 : 1</a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item :label="$t('库位标签长度(mm)')">
                <a-input-number
                  v-decorator="['location_label_length', { rules: [{ required: true, message: $t('请输入库位标签长度') }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('库位标签宽度(mm)')">
                <a-input-number
                  v-decorator="['location_label_width', { rules: [{ required: true, message: $t('请输入库位标签宽度') }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('建议标签比例')"> 2 : 1</a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item :label="$t('箱号标签宽度(mm)')">
                <a-input-number
                  v-decorator="['packing_label_width', { rules: [{ required: true, message: $t('请输入箱号标签长度') }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('箱号标签长度(mm)')">
                <a-input-number
                  v-decorator="['packing_label_length', { rules: [{ required: true, message: $t('请输箱号位标签宽度') }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('建议标签比例')"> 2 : 1</a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item :label="$t('容器标签宽度(mm)')">
                <a-input-number
                  v-decorator="['pallet_label_width', { rules: [{ required: true, message: $t('请输入容器标签长度') }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('容器标签长度(mm)')">
                <a-input-number
                  v-decorator="['pallet_label_length', { rules: [{ required: true, message: $t('请输入容器标签宽度') }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('建议标签比例')"> 2 : 1</a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定保存吗?')" @confirm="create">
          <a-button type="primary" :loading="loading">{{ $t("保存") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { labelConfigsList, labelConfigsSave } from "@/api/system";

export default {
  name: "SystemConfigs",
  components: {},
  data() {
    return {
      loading: false,
      visible: false,
      form: this.$form.createForm(this),
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      labelConfigsList()
        .then((data) => {
          this.$nextTick(() => {
            this.form.setFieldsValue({
              material_label_width: data.material_label_width,
              material_label_length: data.material_label_length,
              stock_in_label_width: data.stock_in_label_width,
              stock_in_label_length: data.stock_in_label_length,
              location_label_width: data.location_label_width,
              location_label_length: data.location_label_length,
              packing_label_width: data.packing_label_width,
              packing_label_length: data.packing_label_length,
              pallet_label_width: data.pallet_label_width,
              pallet_label_length: data.pallet_label_length,
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true;
          labelConfigsSave(values)
            .then(() => {
              this.$message.success(this.$t("保存成功"));
              this.$router.go(0);
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
